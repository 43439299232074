/* Menu Bar Container */
.menu-bar {
  background: linear-gradient(90deg, #ffffff, #2596be, #2596be, #2596be);
  padding: 8px 0;
  border-radius: 10px;
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 2px solid #fff;
}

.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.menu-item {
  margin: 0 20px;
  position: relative;
}

.menu-item a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s, box-shadow 0.3s;
  display: inline-block;
  position: relative;
}

/* Hover Effect for Menu Links */
.menu-item a:hover,
.menu-item a.active {
  /* background-color: #e2e2e2; */
  color: #fff;
  transform: scale(1.05);
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); */
}

/* Animated Underline Effect */
.menu-item a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 3px;
  background-color: #c6c6c6;
  transition: width 0.3s ease, opacity 0.3s ease;
  opacity: 0.8;
}

/* Underline Expansion on Hover */
.menu-item a:hover::after,
.menu-item a.active::after {
  width: 100%;
  opacity: 1;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .menu-list {
    flex-direction: column;
  }

  .menu-item {
    margin: 10px 0;
  }
  .menu-item1 {
    
    margin: 0 5rem;
    padding-left: 12rem;

    
  }
  
  .menu-bar {
    /* background: linear-gradient(180deg, #f4eded, #5a5b96, #2596be); */
    background: 
    linear-gradient(180deg, #ffffff,#2596be,#2596be,#2596be); 
  }
}

.menu-item1 {
  margin: 0 75px;
  padding-right: 190px;
  position: relative;
}

.menu-item1 a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 5px;
  display: inline-block;
  position: relative;
}

.logo-image {
  height: 40px;
  width: auto;
}
