/* Base Styles */
body {
    /* margin: 0; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f4f9;
    color: #333;
}

/* Main Container */
.FDA_Compare {
    display: flex;
    flex-direction: column;
    padding: 30px;
    /* width: 100vh; */
    /* max-width: 1295px;  */
    /* margin: 0 auto;  */
    background: linear-gradient(to bottom right, #F7FAFF, white);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    gap: 20px;
    
    animation: fadeInMain 0.5s ease-in-out;
}

@keyframes fadeInMain {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.Title-heading {
    font-size: 24px;
    font-weight: 700;
    font-family: Arial, sans-serif;
    color: #2596be;
    margin-bottom: 20px;
}

form {
    width: 100%;
}

.select-box {
    width: 100%; 
    background-color: #f8f9fa;
    padding-right: 30px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    padding: 12px;
    margin-top: 10px; 
}


.clear-button {
    background-color: #b05d7a; /* Red for clear button */
    border-color: #b05d7a;
    border-radius: 8px;
    color: #ffffff;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
    overflow: hidden;
    
}



.submit-button {
   

    padding: 10px 20px;
    background-color: #2596be;
    color: #ffffff;
    border: 2px solid #2596be;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.submit-button:hover {
    background-color: #2596be;
}

.submit-button:active {
    background-color: #2596be;
    transform: scale(0.98);
}

.clear-button:hover {
    background-color: #b05d7a;
    border: 2px solid #b05d7a;
    color: #ffffff;
}

.clear-button:active {
    transform: scale(0.98);
}

/* Results and Comparison Sections */
.results-comparison-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    animation: fadeIn 0.5s ease-in-out;
    width: 100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.result, .comparison {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1;
    transition: transform 0.3s, box-shadow 0.3s;
}



.result:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.comparison {
    border-left: 5px solid #2596be;
    background: linear-gradient(135deg, #ffffff 0%, #2596be 100%);
    
}
.result {
    
    border-left: 5px solid #8e8c8d ;

    /* Green border for result */
    
    background: linear-gradient(135deg, #ffffff 0%, #aaa8a9 100%);
}
.comparison:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

pre {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 6px;
    overflow-x: auto;
    font-family: 'Courier New', Courier, monospace;
    font-size: 15px;
    white-space: pre-wrap;
}

/* Error Messages */
.error-box {
    color: #dc3545;
    margin-top: 20px;
    font-size: 16px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .FDA_Compare {
        padding: 15px;
    }

    .results-comparison-container {
        flex-direction: column;
    }

    .result, .comparison {
        margin-bottom: 20px;
    }
    .result {
    
        border-left: 5px solid #8e8c8d ;
    
        /* Green border for result */
        
        background: linear-gradient(135deg, #ffffff 0%, #aaa8a9 100%);
    }
    .comparison:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    }

    .query-input-area {
        align-items: center; /* Center align items on smaller screens */
    }

    .query-input-container {
        flex-direction: column; /* Stack input and button vertically on smaller screens */
        gap: 10px;
    }
}

/* Styles for the autocomplete container */
.App {
    font-family: Arial, sans-serif;
    margin: 20px;
    max-width: 100%;
    position: relative;
}

/* Style for the input field */
input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}

/* Styles for the suggestions list */
.suggestions {
    position: absolute;
    top: 100%;
    width: 85%;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
}

.suggestions.hidden {
    display: none;
}

.suggestions li {
    padding: 10px;
    cursor: pointer;
    list-style: none;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s ease;
}

.suggestions li:hover {
    background-color: #f0f0f0;
}

.suggestions li.no-suggestions {
    color: #999;
    text-align: center;
}


/* Container for the horizontal loader */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 20px auto;
  }
  
  /* Styling for the horizontal loader */
  .loader {
    width: 200px;
    height: 10px;
    background: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  
  /* Animation for the horizontal loader */
  .loader::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, #5a5a73, #2596be, #ffffff, #7e7fa8); 
    animation: slide 1.5s infinite;
  }
  
  /* Keyframes for the sliding effect */
  @keyframes slide {
    0% {
      left: -100px;
    }
    50% {
      left: 100%;
    }
    100% {
      left: 100px;
    }
  }
  
  
  .error-box {
    color: red;
    text-align: center;
  }
  
  .results-comparison-container {
    margin-top: 20px;
  }
  
  .result, .comparison {
    margin: 10px 0;
  }
  
  .submit-button, .clear-button {
    padding: 10px 20px;
    border: none;
    color: white;
    background-color: #2596be;
    cursor: pointer;
  }
  
  .submit-button:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
  }
  
  .clear-button {
    background-color: #ffffff;
    color:#ea7783 ;
    border: 2px solid #ea7783 ;

  }
  
  .input-box, .select-box {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  





  .trending-searches {
    margin-top: 20px;
  }
  
  .trending-searches h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .trending-searches ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between buttons */
  }
  
  .trending-searches li {
    cursor: pointer;
    padding: 12px 20px;
    border: 1px solid #e7e4f5;
    border-radius: 12px; /* Rounded corners for button-like appearance */
    background-color: #f7f7f7;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    position: relative; /* For positioning the arrow */
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for button effect */
    padding-left: 40px; /* Space for the arrow */
  }
  
  .trending-searches li:hover {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
  }

  .trending-heading{
    color: #2596be;
  }
  
  /* .trending-searches li::before {
    content: ''; 
    display: block;
    position: absolute;
    left: 10px; 
    top: 50%;
    width: 10px;
    height: 10px; 
    background-color: #333; 
    transform: translateY(-50%) rotate(45deg); 
    clip-path: polygon(0 0, 100% 0, 0 100%); 
    transition: background-color 0.3s;
   } 
  
  .trending-searches li:hover::before {
    background-color: #007bff; 
  }  */
  
 
  
  