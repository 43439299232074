.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}


.section-box1 {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  /* background-color: #F7FAFF; */
  background: linear-gradient(to bottom right, #F7FAFF, white);
  color: #2596be;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}



.section-header {
  margin: 0 0 10px 0;
  font-size: 1.2em;
}

.change-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.change-item {
  margin-bottom: 10px;
}

.view-changes-button2 {
  padding: 8px 16px;
  background-color: #2596be;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-left: 20px;
}

.view-changes-button2:hover {
  /* background-color: #2596be; */
  background-color: white;
  color: #2596be;
  border: 2px solid #2596be;
}



/* Horizontal loader styling */
.horizontal-loader {
  margin-top: 50px;
  width: 100%;
  height: 10px;
  background-color: #f3f3f3; /* Light grey */
  position: relative;
  overflow: hidden;
}

.horizontal-loader::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  background: linear-gradient(90deg, #5a5a73, #2596be, #ffffff, #7e7fa8); /* Blue, Red, Yellow, Green gradient */
  animation: loading 1.5s linear infinite;
  /* background-color: #2596be;  */
}

@keyframes loading {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}
