.card {
    /* background-color: #f9f9f9; */
    background: linear-gradient(to bottom right, #F7FAFF, white);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    margin: 20px 0;
    padding: 20px;
    width: 400px;
    height: 200px;
}
.card1 {
  /* background-color: #f9f9f9; */
  background: linear-gradient(to bottom right, #F7FAFF, white);
  border-radius: 8px;
  /* display: flex;
  flex-direction: row; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  margin: 20px 0;
  padding: 20px;
  width: 400px;
  height: 150px;
  
}
.Admin-Container{
  
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  max-height: 200px;
}
.card-content {
    display: flex;
    flex-direction: column;
}


.card-item {
    margin-bottom: 10px;
}

.view-changes-button {
    padding: 8px 16px;
    background-color: #2596be;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.view-changes-button:hover {
    /* background-color: #2596be; */
    background-color: white;
    color: #2596be;
    border: 2px solid #2596be;
}

.clickable-card {
    cursor: pointer;
    transition: transform 0.2s;
}

.clickable-card:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
}

.header-reg {
    font-size: 24px;
    font-weight: 700;
    margin-top: 30px;
}

.cumulative-div {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.cumulative-div-1 {
    display: flex;
    justify-content: center;
    gap: 15px;
}


.inline-loader {
    display: inline-block;
    width: 100px; /* Adjust width as needed */
    height: 10px; /* Adjust height as needed */
    background-color: #f3f3f3;
    margin-left: 8px;
    position: relative;
    overflow: hidden;
  }
  
  .loader-bar {
    height: 100%;
    background: linear-gradient(90deg, #5a5a73, #2596be, #ffffff, #7e7fa8);
    position: absolute;
    animation: loading 1.5s infinite;
    width: 100%; /* This ensures the loader bar fills the container */
    transform: translateX(-100%);
  }
  
  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  
